<h1 class="title">Mentor</h1>
<app-breadcrums [data]="breadCrum"></app-breadcrums>
<div class="row">
    <div class="col-md-3 cg-input-group">
        <!-- <label>Title</label>
        <div class="group">
            <input type="text" class="cg" [(ngModel)]="searchKeyword"
                (ngModelChange)="searchKeywordChange($event); filters.page = 1" placeholder="Enter Title to Search" />
        </div> -->
    </div>
    <div class="col-md-3 cg-input-group">
        <!-- <label>Status</label>
        <select class="cg" [(ngModel)]="filters.status" (change)="filters.page = 1; search()">
            <option value="">Select Any</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
        </select> -->
    </div>
    <div class="col cg-input-group d-flex justify-content-between align-items-end">
        <div class="btn-group" dropdown>
            <!-- <button [disabled]="waiting.search" [ngClass]="waiting.search ? 'in-progress' : ''" type="button"
                class="cg secondary" (click)="filters.page = 1; search()">
                Search
                <span></span>
                <span></span>
                <span></span>
            </button> -->
        </div>

        <button type="button" class="cg primary" (click)="openModal(addForm, -1, -1)">Add New</button>
    </div>
</div>
<div class="box">
    <div class="table-responsive">
        <table class="cg primary">
            <thead class="sticky">
                <tr>
                    <th>Detail</th>
                    <!-- <th>Email</th> -->
                    <th>Phone</th>
                    <th>Balance</th>
                    <th>Complete Address</th>
                    <th class="text-center">Status</th>
                    <th style="width: 18%" class="text-center">Actions</th>
                </tr>
            </thead>
            <tbody *ngIf="dataStatus === 'fetching'" [appSkeletonTabelLoader]="loaderOptions"></tbody>
            <tbody *ngIf="dataStatus === 'done' && mentorList.length > 0">
                <tr *ngFor="let list of mentorList,let i = index">
                    <td>
                        First Name: {{list.firstName}} <br>
                        Last Name : {{list.lastName}} <br>
                        Email: {{list.user.email}}

                    </td>
                   
                    <td>
                        {{formatPhoneNumber(list.contactOne)}}
                    </td>
                    <td>
                        ${{list.balance}}
                    </td>
                    
                    <td>
                        {{list.address}} <br/> 
                        {{list.addressTwo}}<br/>
                        {{list.city}},{{list.zipCode}}
                    </td>
                    

                    <td class="text-center">
                        <div *ngIf="list.user.status === 'inactive' || list.user.status === 'Inactive'"
                            class="cg-badge sm warning">Inactive</div>
                        <div *ngIf="list.user.status === 'active' || list.user.status === 'Active'"
                            class="cg-badge sm success">Active</div>
                    </td>

                    <td class="text-center" style="gap: 4px">
                        <button class="cg sm danger mt-1" type="button" (click)="openModal(confirmDel, list.id, i)">
                            <i class="fa fa-trash"></i>
                        </button>

                        <button class="cg sm success mt-1" type="button" (click)="openModal(addForm, list.id, i)">
                            <i class="fa fa-pencil"></i>
                        </button>

                        <button style="width: 65px;" type="button"
                            (click)="statusConfirmingModal(ChangeStatusInactive, list.id, i, 'inactive')"
                            *ngIf="list.user.status === 'active' || list.user.status === 'Active'"
                            class="sm cg ms-1  warning">Deactivate
                            <span></span><span></span><span></span></button>
                        <button style="width: 65px;" type="button"
                            (click)="statusConfirmingModal(ChangeStatusActive, list.id, i, 'active')"
                            class="sm cg  success"
                            *ngIf="list.user.status === 'inactive' || list.user.status === 'Inactive'">Activate
                            <span></span><span></span><span></span></button>

                        <button class="sm ms-1 cg btn-mr primary mt-1" routerLink="/admin/mentors/inspections"
                            [queryParams]="{name:list.name,id:list.id}">Inspections</button>
                        <br>
                        <button class="sm cg info ms-2" [routerLink]="['/admin/mentors/transactions']"
                            [queryParams]="{ mentorId: list.id }">Transactions</button>
                        <button class="sm cg btn-mr success ms-2 mt-1"
                            (click)="openTransactionModal(addTransactionForm, list.id, i)">Add
                            Transaction</button>

                        <button (click)="login(list.id, i)" class="cg primary btn-mr" [disabled]="loginLoading"
                            [ngClass]="loginLoading?'in-progress':''"> Login As Mentor
                            <span></span><span></span><span></span>
                        </button>

                        <!-- <button class="sm cg btn-mr danger ms-2 mt-1"
                            (click)="login(list.id, i)">
                            Login As Mentor</button> -->

                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="mentorList.length === 0 && dataStatus != 'fetching'">
                <tr>
                    <td colspan="7">
                        <app-no-data-found></app-no-data-found>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<app-ipagination [pagination]="pagination" [activePage]="filters.page" (pageChangeEvent)="setPagination($event)"
    *ngIf="dataStatus === 'done'"></app-ipagination>

<ng-template #confirmDel>
    <div class="modal-body text-center">
        <h2 class="primary">Confirmation</h2>
        <p>Do you want to Delete?</p>
        <button [disabled]="waiting.save" [ngClass]="{ 'in-progress': waiting.save }" type="button" class="cg primary"
            (click)="delete()">
            Yes
            <span></span>
            <span></span>
            <span></span>
        </button>
        <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">No</button>
    </div>
</ng-template>

<ng-template #addForm>
    <div class="modal-body">
        <h2 class="modal-title pull-left">{{modalTitle}}</h2>
        <form [formGroup]="dataForm" (ngSubmit)="save(f)" #f="ngForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>First Name <span class="mendatory">*</span></label>
                        <input formControlName="firstName" class="cg mt-0" placeholder="Enter First Name"
                            [ngClass]="{'form-submitted': f.submitted}">
                    </div>
                    <div *ngIf="ui.checkErrors(g.firstName, f)" class="errors">
                        <p *ngIf="g.firstName.errors?.required">First Name is required</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Last Name <span class="mendatory"></span></label>
                        <input formControlName="lastName" class="cg mt-0" placeholder="Enter Last Name"
                            [ngClass]="{'form-submitted': f.submitted}">
                    </div>
                    <div *ngIf="ui.checkErrors(g.lastName, f)" class="errors">
                        <p *ngIf="g.lastName.errors?.required">Last Name is required</p>
                    </div>
                </div>


                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Email <span class="mendatory">*</span></label>
                        <input formControlName="email" class="cg mt-0" placeholder="Enter Email"
                            [ngClass]="{'form-submitted': f.submitted}">
                    </div>
                    <div *ngIf="ui.checkErrors(g.email, f)" class="errors">
                        <p *ngIf="g.email.errors?.required">Email is required</p>
                    </div>
                </div>
                 <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Address<span class="mendatory">*</span></label>
                        <input formControlName="address" class="cg mt-0" placeholder="Enter Address"
                            [ngClass]="{'form-submitted': f.submitted}">
                    </div>
                    <div *ngIf="ui.checkErrors(g.address, f)" class="errors">
                        <p *ngIf="g.address.errors?.required">Title is required</p>
                    </div>
                </div> 
                
                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Address Two<span class="mendatory">*</span></label>
                        <input formControlName="addressTwo" class="cg mt-0" placeholder="Enter Address Two"
                            [ngClass]="{'form-submitted': f.submitted}">
                    </div>
                    <!-- <div *ngIf="ui.checkErrors(g.addressTwo, f)" class="errors">
                        <p *ngIf="g.addressTwo.errors?.required">Address Tow is required</p>
                    </div> -->
                </div> 
                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>House#<span class="mendatory"></span></label>
                        <input formControlName="house" class="cg mt-0" placeholder="Enter Hose" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.house, f)" class="errors">
                        <p *ngIf="g.house.errors?.required">House is required</p>
                    </div> 
                </div> -->
                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Street#<span class="mendatory"></span></label>
                        <input formControlName="street" class="cg mt-0" placeholder="Enter Street" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                     <div *ngIf="ui.checkErrors(g.street, f)" class="errors">
                        <p *ngIf="g.street.errors?.required">Street is required</p>
                    </div>
                </div> -->
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>City<span class="mendatory"></span></label>
                        <input formControlName="city" class="cg mt-0" placeholder="Enter City" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <!-- <div *ngIf="ui.checkErrors(g.city, f)" class="errors">
                        <p *ngIf="g.city.errors?.required">City is required is required</p>
                    </div> -->
                </div>
                <!-- '<div class="col-md-6">
                    <div class="cg-input-group">
                        <label>State<span class="mendatory"></span></label>
                        <input formControlName="state" class="cg mt-0" placeholder="Enter State" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.state, f)" class="errors">
                        <p *ngIf="g.state.errors?.required">State is required</p>
                    </div>
                </div>' -->
                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Zip Code<span class="mendatory"></span></label>
                        <input formControlName="zipCode" class="cg mt-0" placeholder="Enter Zip Code" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <!-- <div *ngIf="ui.checkErrors(g.zipCode, f)" class="errors">
                        <p *ngIf="g.zipCode.errors?.required">Zip Code is required</p>
                    </div> -->
                </div>
                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Phone Number<span class="mendatory">*</span></label>
                        <input formControlName="contact" class="cg mt-0" mask="(000) 000-0000" placeholder="Enter Phone Number"
                            [ngClass]="{'form-submitted': f.submitted}">
                    </div>
                    <div *ngIf="ui.checkErrors(g.contact, f)" class="errors">
                        <p *ngIf="g.contact.errors?.required">Phone number is required</p>
                    </div>
                </div>

            </div>

            <button type="submit" class="cg primary btn-mr" [disabled]="loginLoading"
                [ngClass]="loginLoading?'in-progress':''">Save
                <span></span><span></span><span></span>
            </button>
            <button type="button" class="cg danger" (click)="cancelButton(f)">Cancel</button>
        </form>
    </div>
</ng-template>

<ng-template #ChangeStatusActive>
    <div class="modal-body text-center">
        <h2 class="primary">Confirmation</h2>
        <p>Are you sure that you want change the status to Active?</p>
        <button type="button" class="cg primary" (click)="changeStatusActive()">
            <div class="loading-dots" [ngClass]="loginLoading?'in-progress':''">Yes
                <span></span><span></span><span></span>
            </div>
        </button>
        <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">No</button>
    </div>
</ng-template>

<ng-template #ChangeStatusInactive>
    <div class="modal-body text-center">
        <h2 class="primary">Confirmation</h2>
        <p>Are you sure that you want change the status to Inactive?</p>
        <button type="button" class="cg primary" (click)="changeStatusInactive()">
            <div class="loading-dots" [ngClass]="loginLoading?'in-progress':''">Yes
                <span></span><span></span><span></span>
            </div>
        </button>
        <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">No</button>
    </div>
</ng-template>

<ng-template #addTransactionForm>
    <div class="modal-body">
        <h2 class="modal-title pull-left">{{modalTitle}}</h2>
        <form [formGroup]="transitionForm" (ngSubmit)="saveTransaction(f)" #f="ngForm">
            <div class="row">
                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Amount <span class="mendatory">*</span></label>
                        <input type="number" formControlName="amount" class="cg mt-0" placeholder="Enter Amount"
                            [ngClass]="{'form-submitted': f.submitted}">
                    </div>
                    <div *ngIf="ui.checkErrors(t.amount, f)" class="errors">
                        <p *ngIf="t.amount.errors?.required">Amount is required</p>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label class="mb-0">Description </label>
                        <textarea formControlName="description" cols="20" rows="5" class="cg mt-0"
                            placeholder="Enter Description" [ngClass]="{'form-submitted': f.submitted}"></textarea>
                    </div>
                    <div *ngIf="ui.checkErrors(t.description, f)" class="errors">
                        <p *ngIf="t.description.errors?.required">Description is required</p>
                    </div>
                </div>
            </div>

            <button type="submit" class="cg primary btn-mr" [disabled]="waiting.save"
                [ngClass]="waiting.save?'in-progress':''">Save
                <span></span><span></span><span></span>
            </button>
            <button type="button" class="cg danger ms-2" (click)="cancelButton(f)">Cancel</button>
        </form>
    </div>
</ng-template>