<h1 class="title">Inspections</h1>
<app-breadcrums [data]="breadCrum"></app-breadcrums>
<div class="row">
    <div class="col-md-2 cg-input-group">
        <label>Address</label>
        <div class="group">
            <input type="text" class="cg" [(ngModel)]="filters.keyword" (change)="filters.page = 1; search()" placeholder="Enter Address" (ngModelChange)="searchKeywordChange($event); filters.page = 1" />
        </div>
    </div>
    <div class="col-md-3 cg-input-group">
        <label>Date</label>
        <div style="display: flex; gap: 0.5rem">
            <input type="text" placeholder="From" [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }" class="cg form-control" bsDatepicker [(ngModel)]="filters.startDate" (change)="filters.page = 1; search()" />
             <input type="text" placeholder="To" [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }" class="cg form-control" bsDatepicker [(ngModel)]="filters.endDate" (change)="filters.page = 1; search()" />
        </div>
    </div>
    
    <div class="col-md-2 cg-input-group">
        <label>City</label>
        <div class="group">
            <input type="city" class="cg" [(ngModel)]="filters.city" (change)="search()" placeholder="Enter City" />
        </div>
    </div>
    
    <div class="col cg-input-group d-flex justify-content-between align-items-end">
        <div class="btn-group" dropdown>
            <button [disabled]="waiting.search" [ngClass]="waiting.search ? 'in-progress' : ''" type="button" class="cg secondary" (click)="filters.page = 1; search()">Search <span></span> <span></span> <span></span></button>
            <button type="button" class="cg secondary" (click)="clear()" style="margin-left: 10px">Clear<span></span> <span></span> <span></span></button>
        </div>
        <button type="button" class="cg primary" (click)="openModal(addForm, -1, -1)">Add New</button>
    </div>
</div>
<div class="box">
    <div class="table-responsive">
        <table class="cg primary">
            <thead class="sticky">
                <tr>
                    <th style="width: 30%">Complete Address</th>
                    <th style="width: 10%" class="text-center">
                        Date
                        <span class="arrows">
                            <span (click)="doSortDate('ASC')" [class]="class1"></span>
                            <span (click)="doSortDate('DESC')" [class]="class2"></span>
                        </span>
                    
                    </th>
                    <th>Start Time</th>
                   
                    <!-- <th>End Time</th> -->
                    <th class="text-center">Max Bookings</th>
                    <th class="text-center">Status</th>
                    <th style="width: 20%" class="text-center">Actions</th>
                </tr>
            </thead>
            <tbody *ngIf="dataStatus === 'fetching'" [appSkeletonTabelLoader]="loaderOptions"></tbody>
            <tbody>
                <tr *ngFor="let list of inspectionsList; let i = index">
                    <!-- <td><app-read-more [text]="list.address" [maxLength]="50"></app-read-more></td> -->
                    <!-- <td>{{list.house}}, {{list.street}},  {{list.city}}, {{list.state}} , {{list.zipCode}}</td> -->
                    <td>{{list.address}} <br/>  {{list.addressTwo}}<br/> {{list.city}} , {{list.zipCode}}</td>
                    <td>{{moment(list.date).format('MM-DD-YY')}}</td>
                    <!-- <td>{{ list.startTime }}</td> -->
                    <td>{{moment(list.startTime, 'HH:mm').format('hh:mm A')}}<br /></td>
                    <!-- <td>{{ list.endTime }}</td> -->
                    <td class="text-center">
                        <div *ngIf="list.currentBookings == list.maxBookings" class="danger cg-badge text-center">{{ list.currentBookings }}/{{ list.maxBookings }}</div>
                        <div *ngIf="list.currentBookings != list.maxBookings" class="text-center">{{ list.currentBookings }}/{{ list.maxBookings }}</div>
                    </td>
                    <td class="text-center">
                        <div class="cg-badge lg {{list.status}} text-center" style="font-size: 13px" >{{ list.status }}</div>
                        <!-- <div class="cg-badge lg success text-center" style="font-size: 13px" *ngIf="list.status == 'completed'">{{ list.status }}</div>
                        <div class="cg-badge lg warning text-center" style="font-size: 13px" *ngIf="list.status == 'inProgress'">{{ list.status }}</div>
                        <div class="cg-badge lg danger text-center" style="font-size: 13px" *ngIf="list.status == 'canceled'">{{ list.status }}</div> -->
                    </td>
                    <td class="text-center">
                        <button class="cg sm btn-mr danger mt-1" type="button" style="width: 30px; height: 30px" (click)="openModal(editForm, list.id, i)">
                            <i class="fa-regular fa-pen-to-square"></i>
                        </button>
                        <button style="width: 65px; height: 30px" class="sm cg btn-mr success mt-1" type="button" [routerLink]="['/admin/mentors/inspection/bookings', list.id]">Bookings</button>
                        <button style="width: 65px; height: 30px" class="sm cg btn-mr primary mt-1" type="button" (click)="openModal(requestDetailTemplete, list.id, i)">Detail</button>
                        <br />
                        <!-- <button style="height: 30px" class="sm cg btn-mr success mt-1" type="button" *ngIf="list.status == 'scheduled'" (click)="openModalStatus(changeStatus, list.id, i, 'inProgress')">Mark As InProgress</button> -->
                        <button style="height: 30px" class="sm cg btn-mr danger mt-1" type="button" *ngIf="list.status == 'scheduled'" (click)="openModalStatus(changeStatus, list.id, i, 'canceled')">Cancel Inspection</button>
                        <!--<button style="height: 30px" class="sm cg btn-mr danger mt-1" type="button" *ngIf="list.status == 'inProgress'" (click)="openModalStatus(changeStatus, list.id, i, 'completed')">Mark As Completed</button> -->
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="inspectionsList.length === 0 && dataStatus != 'fetching'">
                <tr>
                    <td colspan="4">
                        <app-no-data-found></app-no-data-found>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<app-ipagination [pagination]="pagination" [activePage]="filters.page" (pageChangeEvent)="setPagination($event)" *ngIf="dataStatus === 'done'"></app-ipagination>

<ng-template #changeStatus>
    <div class="modal-body text-center">
        <h2 class="primary">Confirmation</h2>
        <p>{{ statusTitle }}</p>
        <button [disabled]="waiting.save" [ngClass]="{ 'in-progress': waiting.save }" type="button" class="cg primary" (click)="changeStatusAction()">
            Yes
            <span></span>
            <span></span>
            <span></span>
        </button>
        <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">No</button>
    </div>
</ng-template>
<ng-template #addForm>
    <div class="modal-body">
        <h2 class="modal-title pull-left">{{ modalTitle }}</h2>
        <form [formGroup]="dataForm" (ngSubmit)="save(f)" #f="ngForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Max Bookings<span class="mendatory">*</span></label>
                        <select formControlName="maxBookings" class="cg mt-0">
                            <option value="" disabled>Select Max Bookings</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>

                        </select>
                        <!-- <input formControlName="maxBookings" type="number" class="cg mt-0" placeholder="Enter Max Booking" [ngClass]="{ 'form-submitted': f.submitted }" /> -->
                    </div>
                    <div *ngIf="ui.checkErrors(g.maxBookings, f)" class="errors">
                        <p *ngIf="g.maxBookings.errors?.required">Max Booking is required</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Description<span class="mendatory"></span></label>
                        <textarea formControlName="description" cols="20" rows="5" class="cg mt-0" placeholder="Enter Description" [ngClass]="{ 'form-submitted': f.submitted }"></textarea>
                    </div>
                    <div *ngIf="ui.checkErrors(g.description, f)" class="errors">
                        <p *ngIf="g.description.errors?.required">Description is required is required</p>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>House#<span class="mendatory"></span></label>
                        <input formControlName="house" class="cg mt-0" placeholder="Enter Hose" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.house, f)" class="errors">
                        <p *ngIf="g.house.errors?.required">House is required</p>
                    </div> 
                </div> -->
                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Street#<span class="mendatory"></span></label>
                        <input formControlName="street" class="cg mt-0" placeholder="Enter Street" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.street, f)" class="errors">
                        <p *ngIf="g.street.errors?.required">Street is required</p>
                    </div>
                </div> -->
                
                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>State<span class="mendatory">*</span></label>
                        <input formControlName="state" class="cg mt-0" placeholder="Enter State" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.state, f)" class="errors">
                        <p *ngIf="g.state.errors?.required">State is required</p>
                    </div>
                </div> -->
                
                <div class="col-md-12 date-div">
                    <div class="cg-input-group">
                        <label>Date<span class="mendatory">*</span></label>
                        <i class="fa-regular fa-calendar"></i>
                        <input type="text" class="cg" formControlName="date" placeholder="Select Date" [minDate]="currentDate" bsDatepicker [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }" readonly [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.date, f)" class="errors">
                        <p *ngIf="g.date.errors?.required">Date is required</p>
                    </div>
                </div>
                
              

                
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Start Time<span class="mendatory">*</span></label>
                        <div>
                            <timepicker formControlName="startTime" class="time-wrapper"></timepicker>
                        </div>
                    </div>
                    <div *ngIf="ui.checkErrors(g.startTime, f)" class="errors">
                        <p *ngIf="g.startTime.errors?.required">Time is required</p>
                    </div>
                </div>

                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>End Time<span class="mendatory">*</span></label>
                        <div>
                            <timepicker formControlName="endTime" class="time-wrapper"></timepicker>
                        </div>
                    </div>
                    <div *ngIf="ui.checkErrors(g.endTime, f)" class="errors">
                        <p *ngIf="g.endTime.errors?.required">End Time is required</p>
                    </div>
                </div> -->

             


                <p>Complete Address</p>
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Address<span class="mendatory">*</span></label>
                        <input formControlName="address" class="cg mt-0" placeholder="Enter Address" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.address, f)" class="errors">
                        <p *ngIf="g.address.errors?.required">Address is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Address Two<span class="mendatory">*</span></label>
                        <input formControlName="addressTwo" class="cg mt-0" placeholder="Enter Address Two" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <!-- <div *ngIf="ui.checkErrors(g.address, f)" class="errors">
                        <p *ngIf="g.address.errors?.required">Address is required</p>
                    </div> -->
                </div>
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>City<span class="mendatory">*</span></label>
                        <input formControlName="city" class="cg mt-0" placeholder="Enter City" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.city, f)" class="errors">
                        <p *ngIf="g.city.errors?.required">City is required is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Zip Code<span class="mendatory">*</span></label>
                        <input formControlName="zipCode" class="cg mt-0" placeholder="Enter Zip Code" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.zipCode, f)" class="errors">
                        <p *ngIf="g.zipCode.errors?.required">Zip Code is required</p>
                    </div>
                </div>
              
               
            </div>

            <button type="submit" class="cg primary btn-mr" [disabled]="loginLoading" [ngClass]="loginLoading ? 'in-progress' : ''">Save <span></span><span></span><span></span></button>
            <button type="button" class="cg danger" (click)="cancelButton(f)">Cancel</button>
        </form>
    </div>
</ng-template>
<ng-template #requestDetailTemplete>
    <div class="modal-header">
        <h1 class="modal-title pull-left">Detail</h1>
    </div>
    <div class="modal-body">
        <div class="row">
           
            <div class="col-md-4">
                <h3>Date:</h3>
                {{ requestDetail.date }}
            </div>

            <div class="col-md-4">
                <h3>Start Time:</h3>
                {{moment(requestDetail.startTime, 'HH:mm').format('hh:mm A')}}
               
            </div>
            <!-- <div class="col-md-4">
                <h3>End Time:</h3>
                {{ requestDetail.endTime }}
            </div> -->
        </div>

        <div class="row mt-4">
            <div class="col-md-12">
                <h3>Description:</h3>
                {{ requestDetail.description }}
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-4">
                <h3>Complete Address:</h3>
                {{ requestDetail.address }}<br/>{{requestDetail.addressTwo}}<br/>{{requestDetail.city}}, {{requestDetail.zipCode}}
            </div>
            <!-- <div class="col-md-4">
                <h3>City:</h3>
                {{ requestDetail.city }}
            </div>

            <div class="col-md-4">
                <h3>State:</h3>
                {{ requestDetail.state }}
            </div>

            <div class="col-md-4">
                <h3>Zip-Code:</h3>
                {{ requestDetail.zipCode }}
            </div>
 -->

            
        </div>

        <div class="row mt-4">
           
            <div class="col-md-4">
                <h3>Status:</h3>
                {{ requestDetail.status }}
            </div>

            <div class="col-md-4">
                <h3>MaxBookings:</h3>
                {{ requestDetail.maxBookings }}
            </div>
            <div class="col-md-4">
                <h3>Mentor Name:</h3>
                {{ requestDetail.mentor.name }}
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">Cancel</button>
    </div>
</ng-template>
<ng-template #editForm>
    <div class="modal-body">
        <h2 class="modal-title pull-left">Edit Inspection</h2>
        <form [formGroup]="dataForm" (ngSubmit)="edit(f)" #f="ngForm">
            <div class="row">

               
               
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Max Bookings<span class="mendatory">*</span></label>
                        <select formControlName="maxBookings" class="cg mt-0">
                            <option value="" disabled>Select Max Bookings</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>

                        </select>
                        <!-- <input formControlName="maxBookings" type="number" class="cg mt-0" placeholder="Enter Max Booking" [ngClass]="{ 'form-submitted': f.submitted }" /> -->
                    </div>
                    <div *ngIf="ui.checkErrors(g.maxBookings, f)" class="errors">
                        <p *ngIf="g.maxBookings.errors?.required">Max Booking is required</p>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Description<span class="mendatory"></span></label>
                        <textarea formControlName="description" cols="20" rows="5" class="cg mt-0" placeholder="Enter Description" [ngClass]="{ 'form-submitted': f.submitted }"></textarea>
                    </div>
                    <div *ngIf="ui.checkErrors(g.description, f)" class="errors">
                        <p *ngIf="g.description.errors?.required">Description is required is required</p>
                    </div>
                </div>

                <div class="col-md-12 date-div">
                    <div class="cg-input-group">
                        <label>Date<span class="mendatory">*</span></label>
                        <i class="fa-regular fa-calendar"></i>
                        <input type="text" class="cg" formControlName="date" placeholder="Select Date" [minDate]="currentDate" bsDatepicker [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }" readonly [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.date, f)" class="errors">
                        <p *ngIf="g.date.errors?.required">Date is required</p>
                    </div>
                </div>

                   
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Start Time<span class="mendatory">*</span></label>
                        <div>
                            <timepicker formControlName="startTime" class="time-wrapper"></timepicker>
                        </div>
                    </div>
                    <div *ngIf="ui.checkErrors(g.startTime, f)" class="errors">
                        <p *ngIf="g.startTime.errors?.required">Time is required</p>
                    </div>
                </div>

                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>End Time<span class="mendatory">*</span></label>
                        <div>
                            <timepicker formControlName="endTime" class="time-wrapper"></timepicker>
                        </div>
                    </div>
                    <div *ngIf="ui.checkErrors(g.endTime, f)" class="errors">
                        <p *ngIf="g.endTime.errors?.required">End Time is required</p>
                    </div>
                </div> -->
                

                <p>Complete Address</p>
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Address<span class="mendatory"></span></label>
                        <input formControlName="address" class="cg mt-0" placeholder="Enter Address" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.address, f)" class="errors">
                        <p *ngIf="g.address.errors?.required">Address is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Address Two<span class="mendatory"></span></label>
                        <input formControlName="addressTwo" class="cg mt-0" placeholder="Enter Address Two" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <!-- <div *ngIf="ui.checkErrors(g.address, f)" class="errors">
                        <p *ngIf="g.address.errors?.required">Address is required</p>
                    </div> -->
                </div>
                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>House#<span class="mendatory"></span></label>
                        <input formControlName="house" class="cg mt-0" placeholder="Enter House" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.house, f)" class="errors">
                        <p *ngIf="g.house.errors?.required">House is required</p>
                    </div>
                </div> -->
                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Street#<span class="mendatory">*</span></label>
                        <input formControlName="street" class="cg mt-0" placeholder="Enter Street" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.street, f)" class="errors">
                        <p *ngIf="g.street.errors?.required">Street is required</p>
                    </div>
                </div> -->
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>City<span class="mendatory">*</span></label>
                        <input formControlName="city" class="cg mt-0" placeholder="Enter City" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.city, f)" class="errors">
                        <p *ngIf="g.city.errors?.required">City is required</p>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>State<span class="mendatory">*</span></label>
                        <input formControlName="state" class="cg mt-0" placeholder="Enter State" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.state, f)" class="errors">
                        <p *ngIf="g.state.errors?.required">State is required</p>
                    </div>
                </div> -->
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Zip Code<span class="mendatory">*</span></label>
                        <input formControlName="zipCode" class="cg mt-0" placeholder="Enter Zip Code" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.zipCode, f)" class="errors">
                        <p *ngIf="g.zipCode.errors?.required">Zip Code is required</p>
                    </div>
                </div>

                
            

               
            </div>

            <button type="submit" class="cg primary btn-mr" [disabled]="loginLoading" [ngClass]="loginLoading ? 'in-progress' : ''">Save <span></span><span></span><span></span></button>
            <button type="button" class="cg danger" (click)="cancelButton(f)">Cancel</button>
        </form>
    </div>
</ng-template>
