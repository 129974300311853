import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { LoaderOptionsObject } from 'src/app/interfaces/loaderOptions'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Pagination, Resp } from 'src/app/interfaces/response'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ApiService } from 'src/app/services/api.service'
import { ConstantsService } from 'src/app/services/constants.service'
import { DataService } from './data.service'
import { HttpClient } from '@angular/common/http'
import { apis } from 'src/environments/environment'
@Component({
    selector: 'app-mentor',
    templateUrl: './mentor.component.html',
    styleUrls: ['./mentor.component.css']
})
export class MentorComponent implements OnDestroy, OnInit {
    dataStatus = 'fetching'
    baseUrl: any
    mentorList: Array<any> = []
    selectedIndex: number
    modalRef: BsModalRef
    pagination: Pagination<any>
    searchKeyword = ''
    selectedStatus = ''
    searchKeyword$: Subject<string> = new Subject<string>()
    searchKeywordSub: any
    modalTitle = ''
    loginLoading = false
    dataForm: FormGroup
    transitionForm: FormGroup
    selectedId: any
    d: any
    i: any
    filters = {
        page: 1,
        per_page: 10
    }
    breadCrum = [
        {
            link: '/admin/mentors',
            value: 'Mentors'
        }
    ]
    loaderOptions: LoaderOptionsObject = ConstantsService.createLoaderConfig(5, 7, 1)


    waiting: {
        search: boolean
        save: boolean
        userStatus: Array<any>
    }

    constructor(
        private ds: DataService,
        private fb: FormBuilder,
        public ui: UIHelpers,
        private ms: BsModalService,
        private alert: IAlertService,
        private http: HttpClient,
        public router: Router,
        private route: ActivatedRoute,

        private api: ApiService
    ) {
        this.baseUrl = apis.baseUrl
        this.waiting = {
            search: false,
            save: false,
            userStatus: []
        }

        this.dataForm = this.fb.group({
            firstName: new FormControl(null, [Validators.required, Validators.maxLength(200)]),
            lastName: new FormControl(null, [Validators.maxLength(200)]),
            //house: new FormControl(null, [Validators.maxLength(100)]),
            //street: new FormControl(null, [Validators.maxLength(100)]),
            city: new FormControl(null, [Validators.maxLength(100)]),
            //state: new FormControl(null, [Validators.maxLength(100)]),
            zipCode: new FormControl(null, [Validators.maxLength(100)]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            address: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
            
            addressTwo: new FormControl(null),
            contact: new FormControl(null, [
                Validators.required,
                Validators.max(999999999999999)
            ]),
            id: new FormControl(null)
        })

        this.transitionForm = this.fb.group({
            id: new FormControl(null),
            amount: new FormControl(null, [Validators.required]),
            description: new FormControl('', [Validators.required]),
        })

        this.route.queryParams.subscribe((params) => {
            if (params.page) {
                this.filters.page = params.page
            }
            if (params.perPage) {
                this.filters.per_page = params.perPage
            }
            if (params) {
                this.search()
            }
        })
    }

    ngOnInit(): void {
        this.searchKeywordSub = this.searchKeyword$
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe((searchKeyword) => {
                this.filters.page = 1
                this.search()
            })
    }
    setPagination(page: any) {
        this.filters.page = page
        this.router.navigate(['/admin/mentors'], {
            queryParams: this.filters,
            replaceUrl: true
        })
    }

    searchKeywordChange(value: string) {
        this.searchKeyword$.next(value)
    }

    search(): void {
        this.waiting.search = true
        this.ds.getList(this.filters).subscribe((resp: any) => {
            this.waiting.search = false
            if (resp.success === true) {
                this.mentorList = resp.data.data || []
                this.pagination = resp.data as Pagination<any>
                this.dataStatus = 'done'
            }
        })
    }

    openModal(formModal: any, id: number, index: number) {
        this.modalTitle = 'Add New Mentor'
        this.selectedIndex = index
        if (index > -1) {
            this.modalTitle = 'Edit Mentor'
            this.selectedId = id
            this.selectedIndex = index

            console.log('data---',this.mentorList[index])
            
            let u = this.mentorList[index]
            this.dataForm.patchValue(u)
            this.dataForm.patchValue({email:u.user.email, contact:u.contactOne})
        }
        this.modalRef = this.ms.show(
            formModal,
            {
                class: 'modal-lg modal-dialog-centered back-office-panel',
                backdrop: 'static',
                ignoreBackdropClick: true,
                keyboard: false
            }
        )
    }

    openTransactionModal(formModal: any, id: number, index: number) {
        this.selectedIndex = index
        if (index > -1) {
            this.modalTitle = 'Add Transaction'
            this.selectedId = id
            this.selectedIndex = index
        }
        this.modalRef = this.ms.show(
            formModal,
            {
                class: 'modal-md modal-dialog-centered back-office-panel',
                backdrop: 'static',
                ignoreBackdropClick: true,
                keyboard: false
            }
        )
    }

    save(f: any) {
        this.loginLoading = true
        if (this.dataForm.invalid) {
            this.alert.error('Please fill in complete data & then try again')
            this.loginLoading = false
            return
        }
        if (this.dataForm.value.password !== this.dataForm.value.confirmPassword) {
            this.alert.error('Password does not match')
            this.loginLoading = false
            return
        }
        // let params:any = {
        //     email: this.dataForm.get('email')?.value,
        //     contact: this.dataForm.get('contact')?.value,
        //     address: this.dataForm.get('address')?.value,
        //     name: this.dataForm.get('name')?.value
        // }

        let params = this.dataForm.value

        if(this.dataForm.value.id > -1 && this.dataForm.value.id !=null){
            params.id = this.dataForm.value.id
            this.ds.update(params).subscribe((resp: any) => {
                this.loginLoading = false
                if (resp.success == false) {
                    this.alert.error(resp.errors.general)
                    this.loginLoading = false
                    return
                } else {
                    this.mentorList[this.selectedIndex]= resp.data
                    this.alert.success('Mentor updated successfully!!')
                }
                f.resetForm()
                this.modalRef.hide()
                
            })
        }else{
            this.ds.add(params).subscribe((resp: any) => {
                this.loginLoading = false
                if (resp.status == false) {
                    this.alert.error(resp.errors.general)
                    return
                } else {
                    this.alert.success('Mentor add successfully!!')
                }
                f.resetForm()
                this.modalRef.hide()
                this.mentorList.push(resp.data)
            })
        }
    }

    login(id:any,index:any) {
        this.loginLoading = true

            let params = {
                id:id
            }
            this.api.AdminLoginAsMentor(params).subscribe((resp: any) => {
                this.loginLoading = false
                if (resp.status == false) {
                    this.alert.error(resp.errors.general)
                    return
                } else {
                    this.api.doUserRedirects(resp, this.router)
                }
               
            })
        
    }

    formatPhoneNumber(phoneNumber: string): string {
        const cleanNum = phoneNumber.replace(/\D/g, '');
        const match = cleanNum.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
      }

    saveTransaction(f: any) {
        this.waiting.save = true
        if (this.transitionForm.value.amount <= 0) {
            this.waiting.save = false
            this.alert.error('amount should be greater than 0')
            this.transitionForm.controls.amount.setValue(0)
            return
        }
        if (this.transitionForm.invalid) {
            this.waiting.save = false
            this.alert.error('Please fill-in valid data in all fields & try again.')
            return
        }
        const params = {
            userId: this.selectedId,
            amount: this.transitionForm.value.amount,
            description: this.transitionForm.value.description
        }

        this.ds.addTransaction(params).subscribe((resp: any) => {
            this.waiting.save = false
            if (resp.success === true) {
                this.alert.success('Transaction Added Successfully')
                this.modalRef.hide()
            } else {
                this.alert.error(resp?.errors.general || '')
            }
            this.cancelButton(f)
        })

    }
    get g() {
        return this.dataForm.controls
    }

    get t() {
        return this.transitionForm.controls
    }

    cancelButton(f: any) {
        f.resetForm()
        this.modalRef.hide()
        this.selectedIndex = -1
    }

    statusConfirmingModal(changeStatus: TemplateRef<any>, id: number, ind: number, status: string) {
        this.selectedId = id
        this.selectedIndex = ind
        this.selectedStatus = status
        this.modalRef = this.ms.show(changeStatus, {
            class: 'modal-mg modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }
    cancelRegionButton() {
        this.modalRef.hide()
    }

    ngOnDestroy(): void {
        // this.searchKeywordSub.unsubscribe()
    }
    changeStatusInactive() {
        this.loginLoading = true
        const params = { id: this.selectedId }
        this.ds.deactivate(params).subscribe((resp: any) => {
            console.log(resp)
            this.loginLoading = false
            if (resp.success == false) {
                this.alert.error(resp.errors.general)
                return
            } else {
                this.alert.success(resp.msg)
                this.mentorList[this.selectedIndex].status = this.selectedStatus
                this.search()
                this.modalRef.hide()
            }
        })
    }
    changeStatusActive() {
        this.loginLoading = true
        const params = { id: this.selectedId }
        this.ds.activate(params).subscribe((resp: any) => {
            console.log(resp)
            this.loginLoading = false
            if (resp.success == false) {
                this.alert.error(resp.errors.general)
                return
            } else {
                this.alert.success(resp.msg)
                this.mentorList[this.selectedIndex].status = this.selectedStatus
                this.search()
                this.modalRef.hide()
            }
        })

    }
    delete() {
        this.waiting.save = true
        const params = { id: this.selectedId }
        this.ds.delete(params).subscribe((resp: any) => {
            this.waiting.save = false
            if (resp.success == false) {
                this.alert.error('Not Deleted')
                return
            }
            this.alert.success(resp.msg)
            this.mentorList.splice(this.selectedIndex, 1);
            this.modalRef.hide()
        })

    }
}
