<h1 class="title">Bookings</h1>
<app-breadcrums [data]="breadCrum"></app-breadcrums>
<div class="row">
    <div class="col-md-3 cg-input-group">
        <!-- <label>Title</label>
        <div class="group">
            <input type="text" class="cg" [(ngModel)]="searchKeyword"
                (ngModelChange)="searchKeywordChange($event); filters.page = 1" placeholder="Enter Title to Search" />
        </div> -->
    </div>
    <div class="col-md-3 cg-input-group">
        <!-- <label>Status</label>
        <select class="cg" [(ngModel)]="filters.status" (change)="filters.page = 1; search()">
            <option value="">Select Any</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
        </select> -->
    </div>
    <!-- <div class="col cg-input-group d-flex justify-content-between align-items-end">
        <div class="btn-group" dropdown> -->
    <!-- <button [disabled]="waiting.search" [ngClass]="waiting.search ? 'in-progress' : ''" type="button"
                class="cg secondary" (click)="filters.page = 1; search()">
                Search
                <span></span>
                <span></span>
                <span></span>
            </button> -->
    <!-- </div>

        <button type="button" class="cg primary" (click)="openModal(addForm, -1, -1)">Add New</button>
    </div>
</div> -->
    <div class="box">
        <div class="table-responsive">
            <table class="cg primary">
                <thead class="sticky">
                    <tr>
                        <th style="width: 25%">Name</th>
                        <th style="width: 25%">Address</th>
                        <th style="width: 25%">Contact</th>
                        <th style="width: 25%" class="text-center" style="width: 20%">Actions</th>
                    </tr>
                </thead>
                <tbody *ngIf="dataStatus === 'fetching'" [appSkeletonTabelLoader]="loaderOptions"></tbody>
                <tbody>
                    <tr *ngFor="let list of bookingsList; let i = index">
                        <td>{{ list.name }}</td>
                        <td>{{ list.address }}</td>
                        <td>{{ list.contactOne }}</td>
                        <td class="text-center">
                            <button style="width: 65px; height: 30px" class="sm cg btn-mr danger" type="button" (click)="openModal(confirmDel, list.id, i)">Cancel</button>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="bookingsList.length === 0 && dataStatus != 'fetching'">
                    <tr>
                        <td colspan="4">
                            <app-no-data-found></app-no-data-found>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- <app-ipagination [pagination]="pagination" [activePage]="filters.page" (pageChangeEvent)="setPagination($event)"
    *ngIf="dataStatus === 'done'"></app-ipagination> -->

    <ng-template #confirmDel>
        <div class="modal-body text-center">
            <h2 class="primary">Confirmation</h2>
            <p>Clicking Yes will cancel this booking and cannot be undone</p>
            <button [disabled]="waiting.save" [ngClass]="{ 'in-progress': waiting.save }" type="button" class="cg primary" (click)="cancel()">
                Yes
                <span></span>
                <span></span>
                <span></span>
            </button>
            <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">No</button>
        </div>
    </ng-template>
    <!-- <button (click)="test()">Test</button>  -->
    <!-- <ng-template #addForm>
    <div class="modal-body">
        <h2 class="modal-title pull-left">{{ modalTitle }}</h2>
        <form [formGroup]="dataForm" (ngSubmit)="save(f)" #f="ngForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Title<span class="mendatory">*</span></label>
                        <input formControlName="title" class="cg mt-0" placeholder="Enter Title" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.title, f)" class="errors">
                        <p *ngIf="g.title.errors?.required">Title is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Max Bookings<span class="mendatory">*</span></label>
                        <input formControlName="maxBookings" type="number" class="cg mt-0" placeholder="Enter Max Booking" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.maxBookings, f)" class="errors">
                        <p *ngIf="g.maxBookings.errors?.required">Max Booking is required</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Description<span class="mendatory">*</span></label>
                        <input formControlName="description" class="cg mt-0" placeholder="Enter Description" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.description, f)" class="errors">
                        <p *ngIf="g.description.errors?.required">Description is required is required</p>
                    </div>
                </div>
                <div class="fields-align">
                        <div class="box-input">
                            <div class="cg-input-group">
                                <label>Date<span class="mendatory">*</span></label>
                                <input type="text" class="cg" formControlName="date" placeholder="Select Date" [minDate]="currentDate" bsDatepicker [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }" readonly [ngClass]="{ 'form-submitted': f.submitted }"/>
                            </div>
                            <div *ngIf="ui.checkErrors(g.date, f)" class="errors">
                                <p *ngIf="g.date.errors?.required">Date is required</p>
                            </div>
                        </div>
                        <div class="box-input">
                            <div class="cg-input-group">
                                <label>Time<span class="mendatory">*</span></label>
                                <div>
                                    <timepicker  formControlName = "time" class="time-wrapper"></timepicker>
                                </div>
                            </div>
                            <div *ngIf="ui.checkErrors(g.time, f)" class="errors">
                                <p *ngIf="g.time.errors?.required">Time is required</p>
                            </div>
                        </div>
                </div>




                <p>Location</p>
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Address<span class="mendatory">*</span></label>
                        <input formControlName="address" class="cg mt-0" placeholder="Enter Address" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.address, f)" class="errors">
                        <p *ngIf="g.address.errors?.required">Address is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>City<span class="mendatory">*</span></label>
                        <input formControlName="city" class="cg mt-0" placeholder="Enter Address" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.city, f)" class="errors">
                        <p *ngIf="g.city.errors?.required">City is required is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>State<span class="mendatory">*</span></label>
                        <input formControlName="state" class="cg mt-0" placeholder="Enter Phone Number" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.state, f)" class="errors">
                        <p *ngIf="g.state.errors?.required">State is required</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Postal Code<span class="mendatory">*</span></label>
                        <input formControlName="zipCode" class="cg mt-0" placeholder="Enter Phone Number" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.zipCode, f)" class="errors">
                        <p *ngIf="g.zipCode.errors?.required">Postal Code is required</p>
                    </div>
                </div>

            </div>

            <button type="submit" class="cg primary btn-mr" [disabled]="loginLoading" [ngClass]="loginLoading ? 'in-progress' : ''">Save <span></span><span></span><span></span></button>
            <button type="button" class="cg danger" (click)="cancelButton(f)">Cancel</button>
        </form>
    </div>
</ng-template> -->

    <!-- <ng-template #ChangeStatusActive>
    <div class="modal-body text-center">
        <h2 class="primary">Confirmation</h2>
        <p>Are you sure that you want change the status to Active?</p>
        <button type="button" class="cg primary" (click)="changeStatusActive()">
            <div class="loading-dots" [ngClass]="loginLoading ? 'in-progress' : ''">Yes <span></span><span></span><span></span></div>
        </button>
        <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">No</button>
    </div>
</ng-template> -->

    <!-- <ng-template #ChangeStatusInactive>
    <div class="modal-body text-center">
        <h2 class="primary">Confirmation</h2>
        <p>Are you sure that you want change the status to Inactive?</p>
        <button type="button" class="cg primary" (click)="changeStatusInactive()">
            <div class="loading-dots" [ngClass]="loginLoading ? 'in-progress' : ''">Yes <span></span><span></span><span></span></div>
        </button>
        <button type="button" class="cg danger ms-2" (click)="modalRef.hide()">No</button>
    </div>
</ng-template> -->
</div>
