import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AdminSharedModule } from 'src/app/admin-panel/admin-shared/admin-shared.module'
import { ModalModule } from 'ngx-bootstrap/modal'
import { RouterModule } from '@angular/router'
import { DataService } from './data.service'
import { SharedModule } from 'src/app/website/shared/shared.module'
import { MentorComponent } from './mentor.component'
import { TransactionsComponent } from './transactions/transactions.component'
import { NgSelectModule } from '@ng-select/ng-select'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { BookingsComponent } from './inspection/bookings/bookings.component'
import { InspectionComponent } from './inspection/inspection.component'
import { TimepickerModule } from 'ngx-bootstrap/timepicker'


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        AdminSharedModule,
        BsDatepickerModule.forRoot(),
        NgSelectModule,
        TimepickerModule.forRoot(),
        ModalModule.forRoot(),
        RouterModule.forChild([
            { path: '', component: MentorComponent },
            { path: 'transactions', component: TransactionsComponent },
            { path: 'inspections', component: InspectionComponent },
            { path: 'inspection/bookings/:id', component: BookingsComponent }
        ])
    ],
    declarations: [MentorComponent, TransactionsComponent, InspectionComponent, BookingsComponent],
    providers: [DataService]
})
export class MentorModule {
    // Code Here
}
